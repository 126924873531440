import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import smoothscroll from "smoothscroll-polyfill"
import "pure-react-carousel/dist/react-carousel.es.css"

const StyledSplashThree = styled.div`
  height: 100vh;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  z-index: 0;
  height: 100vh;
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "home-splash-3-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BgImg fluid={data.coverImage.childImageSharp.fluid} />
}

const SplashThree = () => {
  useEffect(() => {
    smoothscroll.polyfill()
  })

  const scrollToAbout = () => {
    document.querySelector("#about").scrollIntoView({ behavior: "smooth" })
  }
  return (
    <StyledSplashThree>
      <Image />
    </StyledSplashThree>
  )
}

export default SplashThree
