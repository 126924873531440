import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import downArrow from "../../../images/down-arrow.svg"

const StyledPortfolio = styled.div`
  position: relative;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  .content {
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    h1 {
      font-size: 5vw;
      margin-bottom: 5vw;
    }
    button {
      background-color: #a8aaad;
      border: none;
      font-size: 1vw;
      padding: 0.7vw 1vw;
      color: white;
      cursor: pointer;
    }
  }
  .left-image {
    width: 25vw;
    position: absolute;
    top: 15%;
    left: 5%;
  }
  .top-right-image {
    width: 20%;
    position: absolute;
    top: 15%;
    right: 25%;
    z-index: 0;
  }
  .bottom-right-image {
    width: 24%;
    position: absolute;
    bottom: 15%;
    right: 15%;
    z-index: 0;
  }
  .scroll {
    position: absolute;
    cursor: pointer;
    background: transparent;
    width: 6vw;
    height: 6vw;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    bottom: 0;
    right: 0;
    /* transform: translateY(1px); */
    z-index: 9;
    img {
      margin: 0;
      width: 2vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-40%);
    }
  }
`

const Portfolio = ({ location, fullpageApi }) => {
  const data = useStaticQuery(graphql`
    query {
      leftImage: file(relativePath: { eq: "home-portfolio-left.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      topRightImage: file(
        relativePath: { eq: "home-portfolio-top-right.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bottomRightImage: file(
        relativePath: { eq: "home-portfolio-bottom-right.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledPortfolio>
      <div className="content">
        <h1>PORTFOLIO</h1>
        <Link to="/portfolio">
          <button>DISCOVER MORE</button>
        </Link>
      </div>
      <div className="left-image">
        <Img fluid={data.leftImage.childImageSharp.fluid} />
      </div>
      <div className="top-right-image">
        <Img fluid={data.topRightImage.childImageSharp.fluid} />
      </div>
      <div className="bottom-right-image">
        <Img fluid={data.bottomRightImage.childImageSharp.fluid} />
      </div>
      <div
        className="scroll"
        onClick={() => {
          fullpageApi.moveSectionDown()
        }}
      >
        <img src={downArrow} alt="down arrow" style={{ margin: 0 }} />
      </div>
    </StyledPortfolio>
  )
}

export default Portfolio
