import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

const StyledContent = styled.div`
  background-color: transparent;
  position: absolute;
  max-width: 34vw;
  top: 50%;
  transform: translateY(-40%);
  left: 6rem;

  .subheading {
    font-size: 1vw;
    color: black;
    margin: 1vw 0;
  }

  .heading {
    font-size: 2vw;
    font-weight: 700;
    font-family: "Akzidenz Grotesk Extended";
    line-height: 2vw;
    color: black;
    margin: 1rem 0;
    position: relative;
  }
`

const Line = styled.div`
  width: 5.5rem;
  border-bottom: 1px solid darkgrey;
  position: absolute;
  left: -6.5rem;
  top: 20%;
`

const Content = ({ location }) => {
  return (
    <StyledContent>
      <motion.div
        initial={{ opacity: 0 }}
        animate={
          ["#splash", ""].includes(location.hash)
            ? { opacity: 1 }
            : { opacity: 0 }
        }
        transition={{ duration: 2 }}
      >
        <p className="heading">Quality Labels.</p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={
          ["#splash", ""].includes(location.hash)
            ? { opacity: 1 }
            : { opacity: 0 }
        }
        transition={{ duration: 2 }}
      >
        <p className="heading">On-Time Delivery.</p>
        <Line />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={
          ["#splash", ""].includes(location.hash)
            ? { opacity: 1 }
            : { opacity: 0 }
        }
        transition={{ duration: 2 }}
      >
        <p className="heading">Competitive Pricing.</p>
      </motion.div>
    </StyledContent>
  )
}

export default Content
