import React from "react"
import styled from "@emotion/styled"

import wings from "../../../images/clients/wings.png"
import pci from "../../../images/clients/pci.png"
import esenses from "../../../images/clients/esenses.png"
import fem from "../../../images/clients/fem.png"
import monfina from "../../../images/clients/monfina.png"
import eplus from "../../../images/clients/eplus.png"
import albens from "../../../images/clients/albens.png"
import vigo from "../../../images/clients/vigo.png"
import westloop from "../../../images/clients/westloop.png"
import thisIsApril from "../../../images/clients/this-is-april.png"
import jamu from "../../../images/clients/jamu.png"
import hex from "../../../images/clients/hex.png"
import sweety from "../../../images/clients/sweety.png"
import everWhite from "../../../images/clients/ever-white.png"
import studioTropik from "../../../images/clients/studio-tropik.png"
import ajinomoto from "../../../images/clients/ajinomoto.png"
import unilever from "../../../images/clients/unilever.png"

const StyledClients = styled.div`
  padding: 4rem 2rem 2rem 2rem;
  .heading {
    font-size: 1.1rem;
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .logo {
      width: 33%;
      box-sizing: border-box;
      padding: 0 1rem 0 1rem;
    }
  }
`
const Clients = ({ location }) => {
  return (
    <StyledClients>
      <div className="wrapper">
        <div className="content">
          <h1 className="heading">OUR SELECTED CLIENTS</h1>
          <div className="logos">
            <div className="logo">
              <img className="image eplus" src={eplus} />
            </div>
            <div className="logo">
              <img className="image wings" src={wings} />
            </div>
            <div className="logo">
              <img className="image ajinomoto" src={ajinomoto} />
            </div>
            <div className="logo">
              <img className="image unilever" src={unilever} />
            </div>
            <div className="logo">
              <img className="image albens" src={albens} />
            </div>
            <div className="logo">
              <img className="image jamu" src={jamu} />
            </div>
            <div className="logo">
              <img className="image hex" src={hex} />
            </div>
            <div className="logo">
              <img className="image monfina long" src={monfina} />
            </div>
            <div className="logo">
              <img className="image studioTropik" src={studioTropik} />
            </div>
            <div className="logo">
              <img className="image sweety" src={sweety} />
            </div>
            <div className="logo">
              <img className="image pci" src={pci} />
            </div>
            <div className="logo">
              <img className="image fem" src={fem} />
            </div>
            <div className="logo">
              <img className="image esenses long" src={esenses} />
            </div>
            <div className="logo">
              <img className="image vigo" src={vigo} />
            </div>
            <div className="logo">
              <img className="image this-is-april long" src={thisIsApril} />
            </div>
          </div>
        </div>
      </div>
      <div className="right"></div>
    </StyledClients>
  )
}

export default Clients
