import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

const StyledContent = styled.div`
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  left: 6rem;

  .heading {
    font-size: 1.25rem;
    font-weight: 700;
    font-family: "Akzidenz Grotesk Extended";
    color: black;
    margin: 1rem 0;
    position: relative;
  }
`

const Line = styled.div`
  width: 5.5rem;
  border-bottom: 1px solid darkgrey;
  position: absolute;
  left: -6.5rem;
  top: 20%;
`

const Content = ({ location }) => {
  return (
    <StyledContent>
      <div>
        <p className="heading">Quality Labels.</p>
      </div>
      <div>
        <p className="heading">On-Time Delivery.</p>
        <Line />
      </div>
      <div>
        <p className="heading">Competitive Pricing.</p>
      </div>
    </StyledContent>
  )
}

export default Content
