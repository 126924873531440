import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

import downArrow from "../../../images/down-arrow.svg"

import wings from "../../../images/clients/wings.png"
import pci from "../../../images/clients/pci.png"
import esenses from "../../../images/clients/esenses.png"
import fem from "../../../images/clients/fem.png"
import monfina from "../../../images/clients/monfina.png"
import eplus from "../../../images/clients/eplus.png"
import albens from "../../../images/clients/albens.png"
import vigo from "../../../images/clients/vigo.png"
// import westloop from "../../../images/clients/westloop.png"
import thisIsApril from "../../../images/clients/this-is-april.png"
import jamu from "../../../images/clients/jamu.png"
import hex from "../../../images/clients/hex.png"
import sweety from "../../../images/clients/sweety.png"
// import everWhite from "../../../images/clients/ever-white.png"
import studioTropik from "../../../images/clients/studio-tropik.png"
import ajinomoto from "../../../images/clients/ajinomoto.png"
import unilever from "../../../images/clients/unilever.png"

const StyledClients = styled.div`
  height: 100vh;
  .wrapper {
    display: grid;
    grid-template-columns: 9fr 11fr 7fr;
    height: 100%;
    .left {
      position: relative;
      .line {
        position: absolute;
        right: 2vw;
        bottom: 0;
        width: 1px;
        height: 35vw;
        background-color: lightgrey;
        overflow-y: hidden;
      }
    }

    .middle {
      display: flex;
      align-items: center;
      padding-left: 3vw;
      .content {
        h1 {
          font-size: 2vw;
        }
        .logos {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-gap: 2vw;
          width: 100%;
          .logo {
            width: 10vw;
            height: 5vw;
            text-align: center;
            display: flex;
            align-items: center;
            .image {
              margin: 0;
              height: 100%;
              margin: 0 auto;
            }
            .long {
              width: 100%;
              height: auto;
            }
            .hex {
              height: auto;
              width: 60%;
            }
            .vigo {
              height: 60%;
            }
            .wings {
              height: 80%;
            }
            .fem {
              height: 80%;
            }
          }
        }
      }
    }

    .right {
    }
  }
  .scroll {
    cursor: pointer;
    position: absolute;
    background: transparent;
    width: 6vw;
    height: 6vw;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    bottom: 0;
    right: 0;
    img {
      margin: 0;
      width: 2vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-40%);
    }
  }
`

const Clients = ({ location, fullpageApi }) => {
  return (
    <StyledClients>
      <div className="wrapper">
        <div className="left">
          <div className="line" />
        </div>
        <div className="middle">
          <div className="content">
            <h1>OUR SELECTED CLIENTS</h1>
            <div className="logos">
              <div className="logo">
                <img className="image eplus" src={eplus} />
              </div>
              <div className="logo">
                <img className="image wings" src={wings} />
              </div>
              <div className="logo">
                <img className="image ajinomoto" src={ajinomoto} />
              </div>
              <div className="logo">
                <img className="image unilever" src={unilever} />
              </div>
              <div className="logo">
                <img className="image albens" src={albens} />
              </div>
              <div className="logo">
                <img className="image jamu" src={jamu} />
              </div>
              <div className="logo">
                <img className="image hex" src={hex} />
              </div>
              <div className="logo">
                <img className="image monfina long" src={monfina} />
              </div>
              <div className="logo">
                <img className="image studioTropik" src={studioTropik} />
              </div>
              <div className="logo">
                <img className="image sweety" src={sweety} />
              </div>
              <div className="logo">
                <img className="image pci" src={pci} />
              </div>
              <div className="logo">
                <img className="image fem" src={fem} />
              </div>
              <div className="logo">
                <img className="image esenses long" src={esenses} />
              </div>
              <div className="logo">
                <img className="image vigo" src={vigo} />
              </div>
              <div className="logo">
                <img className="image this-is-april long" src={thisIsApril} />
              </div>
            </div>
          </div>
        </div>
        <div className="right"></div>
      </div>
      <div
        className="scroll"
        onClick={() => {
          fullpageApi.moveSectionDown()
        }}
      >
        <img src={downArrow} alt="down arrow" style={{ margin: 0 }} />
      </div>
    </StyledClients>
  )
}

export default Clients
