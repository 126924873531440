import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import downArrow from "../../../images/down-arrow.svg"

const StyledPortfolio = styled.div`
  position: relative;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  .content {
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    h1 {
      font-size: 2rem;
    }
    button {
      background-color: #a8aaad;
      border: none;
      font-size: 1rem;
      padding: 1rem 1rem;
      color: white;
      cursor: pointer;
    }
  }
  .left-image {
    width: 50%;
    position: absolute;
    top: 15%;
    left: 5%;
  }
  .top-right-image {
    width: 40%;
    position: absolute;
    top: 25%;
    right: 5%;
    z-index: 0;
  }
  .bottom-right-image {
    width: 60%;
    position: absolute;
    bottom: 15%;
    right: 15%;
    z-index: 0;
  }
`

const Portfolio = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      leftImage: file(relativePath: { eq: "home-portfolio-left.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      topRightImage: file(
        relativePath: { eq: "home-portfolio-top-right.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bottomRightImage: file(
        relativePath: { eq: "home-portfolio-bottom-right.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledPortfolio>
      <div className="content">
        <h1>PORTFOLIO</h1>
        <Link to="/portfolio">
          <button>DISCOVER MORE</button>
        </Link>
      </div>
      <div className="left-image">
        <Img fluid={data.leftImage.childImageSharp.fluid} />
      </div>
      <div className="top-right-image">
        <Img fluid={data.topRightImage.childImageSharp.fluid} />
      </div>
      <div className="bottom-right-image">
        <Img fluid={data.bottomRightImage.childImageSharp.fluid} />
      </div>
    </StyledPortfolio>
  )
}

export default Portfolio
