import React from "react"
import styled from "@emotion/styled"
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { Link } from "gatsby"

import scrollArrow from "../../../images/scroll-arrow.svg"
import SplashOne from "./SplashOne"
import SplashTwo from "./SplashTwo"
import SplashThree from "./SplashThree"
import Content from "./Content"

const StyledHero = styled.div`
  overflow: hidden;
  .carousel {
    position: relative;
  }
  .carousel__slider {
    height: 100vh;
    @media screen and (max-width: 800px) {
      height: 90vh;
    }
  }
  .carousel__slide {
    background-color: deeppink;
  }
  .pagination {
    position: absolute;
    right: 3vw;
    bottom: 50%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .control {
    position: absolute;
    right: 5vw;
    bottom: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    transform: translateY(1vh);
    @media screen and (max-width: 800px) {
      display: none;
    }
    .carousel__back-button {
      margin: 0;
      padding: 0;
      position: relative;
      background: transparent;
      border: none;
      img {
        width: 20px;
        margin: 0;
      }
    }
    .carousel__next-button {
      margin: 0;
      padding: 0;
      position: relative;
      background: transparent;
      border: none;
      img {
        width: 20px;
        margin: 0;
      }
    }
  }
  .carousel__dot {
    margin: 0.3rem;
    background-color: transparent;
    border-radius: 50%;
    border: solid 1px #595a5c;
    width: 6px;
    height: 6px;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
  .carousel__dot--selected {
    background-color: #595a5c;
    transition: background-color 0.5s;
  }
`

const StyledScroll = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  .arrow {
    cursor: pointer;
    height: 2.5vw;
    width: 2.5vw;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 1.5vw;
      margin: 0;
    }
  }

  .scroll {
    cursor: pointer;
    font-size: 0.9vw;
    letter-spacing: 0.1vw;
    color: white;
    background-color: grey;
    padding: 1vw 5vw;
  }
`

const Scroll = props => (
  <StyledScroll>
    <div
      className="arrow"
      onClick={() => {
        props.fullpageApi.moveSectionDown()
      }}
    >
      <img src={scrollArrow} alt="scroll-arrow" />
    </div>
    <div
      className="scroll"
      onClick={() => {
        props.fullpageApi.moveSectionDown()
      }}
    >
      SCROLL TO DISCOVER
    </div>
  </StyledScroll>
)

const Hero = ({ location, fullpageApi }) => {
  return (
    <StyledHero>
      <CarouselProvider
        naturalSlideWidth={10}
        naturalSlideHeight={30}
        totalSlides={3}
        isPlaying={true}
        interval={5000}
        infinite={true}
      >
        <Slider>
          <Slide index={0}>
            <SplashOne />
          </Slide>
          <Slide index={1}>
            <SplashTwo />
          </Slide>
          <Slide index={2}>
            <SplashThree />
          </Slide>
        </Slider>
        <div className="pagination">
          <Dot slide={0} className="active" />
          <Dot slide={1} />
          <Dot slide={2} />
        </div>
      </CarouselProvider>
      <Content location={location} />
      <Scroll fullpageApi={fullpageApi} />
    </StyledHero>
  )
}

export default Hero
