import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import Footer from "../../Footer"

const StyledServices = styled.div`
  min-height: 100vh;
  .wrapper {
    border-top: 1px solid black;
    padding-top: 100px;
    .header {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      text-align: center;
      padding: 1.5vw;
      h1 {
        font-size: 1.5vw;
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .left {
        .title {
        }
        .products {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .materials {
            position: relative;
            &:hover {
              color: white;
            }
            .materials__heading {
              pointer-events: none;
              position: absolute;
              top: 4vw;
              left: 3vw;
              h1 {
                font-size: 1.7vw;
                font-weight: 600;
              }
            }
            .materials__description {
              pointer-events: none;
              position: absolute;
              top: 65%;
              left: 3vw;
              padding-right: 3vw;
            }
          }
          .finishing {
            position: relative;
            &:hover {
              color: white;
            }
            .finishing__heading {
              pointer-events: none;
              position: absolute;
              top: 4vw;
              left: 3vw;
              h1 {
                pointer-events: none;
                font-size: 1.7vw;
                font-weight: 600;
              }
            }
            .finishing__description {
              pointer-events: none;
              position: absolute;
              top: 65%;
              left: 3vw;
              padding-right: 3vw;
            }
          }
        }
      }
      .right {
        .title {
          border-left: 1px solid black;
          background-color: black;
          color: white;
          font-weight: 600;
        }
        .services {
          display: grid;
          .quality {
            position: relative;
            &:hover {
              color: white;
            }
            .quality__heading {
              pointer-events: none;
              position: absolute;
              top: 4vw;
              left: 3vw;
              h1 {
                pointer-events: none;
                font-size: 1.7vw;
                font-weight: 600;
              }
            }
            .quality__description {
              pointer-events: none;
              position: absolute;
              top: 65%;
              left: 3vw;
              width: 60%;
            }
          }
        }
      }
      .title {
        font-family: "Akzidenz Grotesk Extended";
        text-align: center;
        padding: 1vw 0;
      }
    }
  }
  a {
    color: black;
  }
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 70vh;
  z-index: 0;
  overflow: hidden;
  transition: filter ease 0.3s;
  opacity: 20%;
  cursor: pointer;
  &:hover {
    filter: brightness(30%);
    opacity: 100%;
    transition: filter ease 0.3s;
  }
  img {
    object-fit: cover !important;
    object-position: bottom ${props => (props.position ? "right" : "center")} !important;
  }
`

const Images = ({ n }) => {
  const data = useStaticQuery(graphql`
    query {
      materialsImage: file(relativePath: { eq: "home-materials.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      finishingImage: file(relativePath: { eq: "home-finishing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      qualityControlImage: file(
        relativePath: { eq: "home-quality-control.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  switch (n) {
    case 1:
      return <BgImg fluid={data.materialsImage.childImageSharp.fluid} />
    case 2:
      return (
        <BgImg
          fluid={data.finishingImage.childImageSharp.fluid}
          position="right"
        />
      )
    case 3:
      return <BgImg fluid={data.qualityControlImage.childImageSharp.fluid} />
    default:
      return
  }
}

const Services = ({ location }) => {
  return (
    <StyledServices>
      <div className="haha-wrapper">
        <div className="wrapper">
          <div className="header">
            <h1>OUR SERVICES</h1>
            <p>Creating a structured methodology</p>
          </div>
          <div className="content">
            <div className="left">
              <div className="title">PRODUCTS</div>
              <div className="products">
                <Link to="/services">
                  <div className="materials">
                    <Images n={1} />
                    <div className="materials__heading">
                      <h1>Materials</h1>
                    </div>
                    <div className="materials__description">
                      <p>
                        We use several variations of film and paper substrates
                        for our standard labels, with each material having their
                        own advantages and characteristics, so that you’ll know
                        what’s best for your product.
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="/services">
                  <div className="finishing">
                    <Images n={2} />
                    <div className="finishing__heading">
                      <h1>Choose Your Finishing</h1>
                    </div>
                    <div className="finishing__description">
                      <p>
                        The finishing process is one that is quite simple and
                        puts accuracy above all the rest.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="right">
              <div className="title">SERVICES</div>
              <div className="services">
                <Link to="/services">
                  <div className="quality">
                    <Images n={1} />
                    <div className="quality__heading">
                      <h1>Quality Control</h1>
                    </div>
                    <div className="quality__description">
                      <p>
                        The quality production we create stems not only from the
                        quality of our relationship with the client, but also
                        the effective use of our sophisticated technologies and
                        the abundance of our materials.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledServices>
  )
}

export default Services
