import React, { useState } from "react"
import styled from "@emotion/styled"
import ReactFullpage from "@fullpage/react-fullpage"
import { useMediaQuery } from "react-responsive"

import SplashDesktop from "./SplashDesktop"
import SplashMobile from "./SplashMobile"
import Clients from "./Clients"
import ClientsMobile from "./ClientsMobile"
import Portfolio from "./Portfolio"
import PortfolioMobile from "./PortfolioMobile"
import Services from "./Services"
import NewServices from "./NewServices"
import ServicesMobile from "./ServicesMobile"
import Footer from "../Footer"

const SEL = "custom-section"
const SECTION_SEL = `.${SEL}`

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {
  //   require("./fullpage.fadingEffect.min") // Optional. Required when using the "fadingEffect" extension.
}

const StyledHome = styled.div`
  min-height: 100vh;
`

const Home = ({ location }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 900px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" })

  const onLeave = (origin, destination, direction) => {
    console.log("onLeave", { origin, destination, direction })
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
  }

  return (
    <StyledHome>
      {isDesktop && (
        <ReactFullpage
          debug
          pluginWrapper={pluginWrapper}
          // fullpage options
          licenseKey={"C1D12236-33A84A31-8C484A48-9B01E5CA"}
          // navigation
          anchors={["splash", "clients", "portfolio", "services"]}
          sectionSelector={SECTION_SEL}
          onLeave={onLeave}
          render={fullpageApi => (
            <div>
              <ReactFullpage.Wrapper>
                <div className={SEL}>
                  <SplashDesktop
                    location={location}
                    fullpageApi={fullpageApi.fullpageApi}
                  />
                </div>
                <div className={SEL}>
                  <Clients
                    location={location}
                    fullpageApi={fullpageApi.fullpageApi}
                  />
                </div>
                <div className={SEL}>
                  <Portfolio
                    location={location}
                    fullpageApi={fullpageApi.fullpageApi}
                  />
                </div>
                <div className={SEL}>
                  <NewServices location={location} />
                </div>
              </ReactFullpage.Wrapper>
            </div>
          )}
        />
      )}
      {isMobile && (
        <div>
          <SplashMobile location={location} />
          <ClientsMobile location={location} />
          <PortfolioMobile location={location} />
          <ServicesMobile location={location} />
        </div>
      )}
    </StyledHome>
  )
}

export default Home
