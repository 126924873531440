import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import FooterServices from "./FooterServices"

const StyledNewServices = styled.div`
  height: 100vh;
  padding-top: 13vh;
  position: relative;
  .heading-wrapper {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    text-align: center;
    padding-top: 1rem;
    height: 12vh;
    padding-bottom: 1rem;
    h1 {
      margin: 0;
      font-size: 1.5rem;
    }
    p {
      margin: 0;
    }
  }
  .categories {
    a {
      color: black;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    .products {
      &:hover {
        .products-heading {
          background-color: black;
          color: white;
        }
      }
      .products-heading {
        text-align: center;
        /* border-right: 1px solid black; */
        font-family: "Akzidenz Grotesk Extended";
        padding: 0.75rem;
      }
      .products-content {
        display: grid;
        height: 100%;
        grid-template-columns: 1fr 1fr;
        .materials {
          position: relative;
          &:hover {
            color: white;
          }
          .materials-content {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            padding: 2rem 1.5rem;
            h1 {
              font-size: 1.5rem;
              font-weight: 600;
              margin-bottom: 3rem;
              &:hover {
                color: white;
              }
            }
          }
        }
        .finishing {
          position: relative;
          &:hover {
            color: white;
          }
          .finishing-content {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            padding: 2rem 1.5rem;
            h1 {
              font-size: 1.5rem;
              font-weight: 600;
              margin-bottom: 3rem;
              &:hover {
                color: white;
              }
            }
          }
        }
      }
    }
    .service {
      &:hover {
        .service-heading {
          background-color: black;
          color: white;
        }
      }
      .service-heading {
        text-align: center;
        font-family: "Akzidenz Grotesk Extended";
        padding: 0.75rem;
      }
      .service-content {
        height: 100%;
        position: relative;
        &:hover {
          color: white;
        }
        .quality-content {
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
          padding: 2rem 1.5rem;
          h1 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 3rem;
            &:hover {
              color: white;
            }
          }
        }
      }
    }
  }
  .footer-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 18vh;
    color: white;
  }
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 70vh;
  z-index: 0;
  overflow: hidden;
  transition: filter ease 0.3s;
  opacity: 20%;
  cursor: pointer;
  &:hover {
    filter: brightness(30%);
    opacity: 100%;
    transition: filter ease 0.3s;
  }
  img {
    object-fit: cover !important;
    object-position: bottom ${props => (props.position ? "right" : "center")} !important;
  }
`

const Images = ({ n }) => {
  const data = useStaticQuery(graphql`
    query {
      materialsImage: file(relativePath: { eq: "home-materials.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      finishingImage: file(relativePath: { eq: "home-finishing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      qualityControlImage: file(
        relativePath: { eq: "home-quality-control.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  switch (n) {
    case 1:
      return <BgImg fluid={data.materialsImage.childImageSharp.fluid} />
    case 2:
      return (
        <BgImg
          fluid={data.finishingImage.childImageSharp.fluid}
          position="right"
        />
      )
    case 3:
      return <BgImg fluid={data.qualityControlImage.childImageSharp.fluid} />
    default:
      return
  }
}

const NewServices = ({ location }) => {
  return (
    <StyledNewServices>
      <div className="heading-wrapper">
        <h1>OUR SERVICES</h1>
        <p>Creating a structured methodology</p>
      </div>
      <div className="categories">
        <div className="products">
          <div className="products-heading">PRODUCTS</div>
          <Link to="/services">
            <div className="products-content">
              <div className="materials">
                <Images n={1} />
                <div className="materials-content">
                  <div style={{ height: "150px" }}>
                    <h1>Materials</h1>
                  </div>
                  <p>
                    We use several variations of film and paper substrates for
                    our standard labels, with each material having their own
                    advantages and characteristics, so that you’ll know what’s
                    best for your product.
                  </p>
                </div>
              </div>
              <div className="finishing">
                <Images n={2} />
                <div className="finishing-content">
                  <div style={{ height: "150px" }}>
                    <h1>Choose Your Finishing</h1>
                  </div>
                  <p>
                    The finishing process is one that is quite simple and puts
                    accuracy above all the rest.
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="service">
          <div className="service-heading">SERVICE</div>
          <Link to="/services">
            <div className="service-content">
              <Images n={3} />
              <div className="quality-content">
                <div style={{ height: "150px" }}>
                  <h1>Quality Control</h1>
                </div>
                <p>
                  The quality production we create stems not only from the
                  quality of our relationship with the client, but also the
                  effective use of our sophisticated technologies and the
                  abundance of our materials.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="footer-wrapper">
        <FooterServices />
      </div>
    </StyledNewServices>
  )
}

export default NewServices
