import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const StyledFooterLinks = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  justify-content: space-around;

  a {
    color: white;
    font-size: 0.6rem;
  }
`

const Item = styled.div``

const FooterLinks = props => {
  return (
    <StyledFooterLinks>
      <Item>
        <Link to="/about">ABOUT</Link>
      </Item>
      <Item>
        <Link to="/services">SERVICES</Link>
      </Item>
      <Item>
        <Link to="/portfolio">PORTFOLIO</Link>
      </Item>
      <Item>
        <Link to="/contact">CONTACT</Link>
      </Item>
    </StyledFooterLinks>
  )
}

export default FooterLinks
