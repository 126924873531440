import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import smoothscroll from "smoothscroll-polyfill"
import "pure-react-carousel/dist/react-carousel.es.css"

const StyledSplashThree = styled.div`
  height: 100vh;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 800px) {
    height: 90vh;
  }
  .text {
    z-index: 2;
    position: absolute;
    color: white;
    left: 5vw;
    width: 30%;
    transform: translateY(-40%);
    margin: 0;
    @media only screen and (max-width: 800px) {
      width: 100%;
      left: 0;
      padding: 0 2rem;
    }
    h1 {
      font-weight: 300;
      font-size: 3.5vw;
      line-height: 4vw;
      @media only screen and (max-width: 800px) {
        font-size: 2rem;
        line-height: 2.2rem;
      }
      .the-future {
        text-decoration: underline;
      }
    }
    .belajar-button {
      border: none;
      background-color: #d23c6b;
      color: white;
      cursor: pointer;
      @media only screen and (max-width: 800px) {
        width: 100%;
        height: 40px;
      }
      .belajar-button-content {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 4.5vw;
        align-items: center;
        @media only screen and (max-width: 800px) {
          display: flex;
          justify-content: space-between;
        }
        img {
          width: 17px;
          margin: 0;
        }
      }
    }
  }
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  z-index: 0;
  height: 100vh;
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "home-splash-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <BgImg fluid={data.coverImage.childImageSharp.fluid} />
}

const SplashThree = () => {
  useEffect(() => {
    smoothscroll.polyfill()
  })

  const scrollToAbout = () => {
    document.querySelector("#about").scrollIntoView({ behavior: "smooth" })
  }
  return (
    <StyledSplashThree>
      <Image />
    </StyledSplashThree>
  )
}

export default SplashThree
