import React from "react"
import styled from "@emotion/styled"
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import scrollArrow from "../../../images/scroll-arrow.svg"
import SplashOne from "./SplashOne"
import SplashTwo from "./SplashTwo"
import SplashThree from "./SplashThree"
import Content from "./Content"

const StyledSplashMobile = styled.div`
  overflow: hidden;
  .carousel {
    position: relative;
  }
  .carousel__slider {
    height: 100vh;
  }
  .carousel__slide {
    background-color: none;
  }
  .pagination {
    position: absolute;
    right: 3vw;
    bottom: 50%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .control {
    position: absolute;
    right: 5vw;
    bottom: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    transform: translateY(1vh);
    @media screen and (max-width: 800px) {
      display: none;
    }
    .carousel__back-button {
      margin: 0;
      padding: 0;
      position: relative;
      background: transparent;
      border: none;
      img {
        width: 20px;
        margin: 0;
      }
    }
    .carousel__next-button {
      margin: 0;
      padding: 0;
      position: relative;
      background: transparent;
      border: none;
      img {
        width: 20px;
        margin: 0;
      }
    }
  }
  .carousel__dot {
    margin: 0.3rem;
    background-color: transparent;
    border-radius: 50%;
    border: solid 1px #595a5c;
    width: 6px;
    height: 6px;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
  .carousel__dot--selected {
    background-color: #595a5c;
    transition: background-color 0.5s;
  }
`

const SplashMobile = ({ location }) => {
  return (
    <StyledSplashMobile>
      <CarouselProvider
        naturalSlideWidth={10}
        naturalSlideHeight={30}
        totalSlides={3}
        isPlaying={true}
        interval={5000}
        infinite={true}
      >
        <Slider>
          <Slide index={0}>
            <SplashOne />
          </Slide>
          <Slide index={1}>
            <SplashTwo />
          </Slide>
          <Slide index={2}>
            <SplashThree />
          </Slide>
        </Slider>
        <div className="pagination">
          <Dot slide={0} className="active" />
          <Dot slide={1} />
          <Dot slide={2} />
        </div>
      </CarouselProvider>
      <Content location={location} />
    </StyledSplashMobile>
  )
}

export default SplashMobile
